<template>
  <AppPage
    :show-menu="false"
    class="centered"
    :show-footer="false"
  >
    <div class="section">
      <h2>Auth</h2>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import Button from "@/components/atoms/Button.vue";
import AppPage from "@/components/organisms/AppPage.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin";

export default Vue.extend({
  components: {
    AppPage
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters['user/isAuthenticated'];
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';
.centered{
  text-align: center;
  font-size: 110%;
}
.section {
  background: @white;
  padding: 2em 2em;
  border-radius: 1em;
  width: 400px;
  max-width: 90vw !important;
  box-sizing: border-box;
  border:2px solid @grayLight;
}

/deep/ .page-content{
  margin: 0 auto;
}
h2{
  margin-top: 0.5em;
}

p{
  margin: 0 0 1em 0;
  a{
    font-size: 80%;
    color: @purpleLight;
    &:hover{
      color: @grayLight;
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
form{
  margin:0 auto 1em auto;
  input, button{
    padding: 0.9rem 1.5rem;
    font: inherit;
    box-sizing: border-box;
    clear: both;
    width: 100%;
    border-radius: 6px;
    border: none;
    text-align: center;
    outline: none;
  }
  input{
    margin-bottom: 1em;
    background: @grayLightMedium;
    &:focus{
      background: @grayLight;
    }
  }

  button{
    font-weight: 600;
  }
}

p.error {
  color: #fc7a7a;
  font-size: 90%;
}
p.green {
  color: #b3fc96;
}


</style>
